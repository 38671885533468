import React, { useState } from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Tariffs = () => {
    const breakpoints = useBreakpoint();
    const Data = [
        {
            title: "Products",
            list: [
                {title:"Users", Standard:"< 100,000", Scale:"100,000-500,000",Enterprise:"> 500,000"},
                {title:"Author", Standard:"true", Scale:"true",Enterprise:"true"},
                {title:"Questions", Standard:"true", Scale:"true",Enterprise:"true"},
                {title:"Math", Standard:"true", Scale:"true",Enterprise:"true"},
                {title:"Assessments-Essentials	", Standard:"true", Scale:"true",Enterprise:"true"},
                {title:"Assessments-Premium", Standard:"charges", Scale:"charges",Enterprise:"true"},
                {title:"Analytics-Essentials", Standard:"true", Scale:"true",Enterprise:"true"},
                {title:"Analytics-Premium", Standard:"charges", Scale:"charges",Enterprise:"true"},
                {title:"Support", Standard:"Silver", Scale:"Gold",Enterprise:"Platinum"},
            ]
        },
        {
            title: "Platform",
            list: [
                {title:"Single Tenancy", Standard:"false", Scale:"true",Enterprise:"true"},
                {title:"Multi-region	", Standard:"false", Scale:"true",Enterprise:"true"},
            ]
        }
    ];

    const [active, setActive] = useState(0);

    const DataAnalyser = (s) => {
        let data;
        if (s === "true") {
           data = <div>
               <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_orange.svg" alt="tick" className="m-0 w-4"/>
           </div> 
        }
        else if(s === "false") {
            data = <div>
                <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/cancel_mark_icon.svg" alt="cancel" className="m-0 w-4"/>
            </div>
        }
        else if (s === "charges") {
            data = <div>
                <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/dollar_sign_icon.svg" alt="dollar sign" className="m-0 w-7"/>
            </div>
        } else {
            data = <div className="text-sm font-normal">
                {s}
            </div>
        }
        return data;
    }

    const leftSliderHandler = () => {
        if (active !== 0) {
            setActive(active - 1)
        }
    }

    const rightSliderHandler = () => {
        if (active < 2) {
            setActive(active + 1)
        }
    }

    return (
        <div className="space-y-6 md:space-y-10">
            <div className={`text-xs md:text-base font-semibold opacity-60 text-center`}>
                TARIFFS
            </div>
            <div className={` text-3xl md:text-6xl text-center font-bold  w-10/12 lg:w-4/5 mx-auto`}>
                Tariffs adapted <br className=" md:hidden"/> to each stage of <br className=" md:hidden"/>development
            </div>
            <div className="md:hidden flex flex-col items-center space-y-6">
                <div className="flex flex-row space-x-6 w-full">
                <div
                    className="bg-white bg-opacity-30  text-black  rounded-r-3xl w-1/6 cursor-pointer"
                    onClick={() => leftSliderHandler()}
                >     
                </div>
                <div className="flex flex-col space-y-3 justify-center w-4/6 items-center bg-white text-black py-5  rounded-3xl">
                    <div>
                        <img src={`icons/${active === 0 ? "new_briefcase_icon_with_bg.svg" : active === 1 ? "scale_icon_with_bg.svg" : "3_blocks_icon_with_bg.svg"}` } alt="thumbs_up icon" className="m-0 h-10"/>
                    </div>
                    <div className="text-xl font-semibold">
                        {active===0?"Standard":active===1?"Scale":"Enterprise"}
                    </div>
                    <div className="text-base font-medium">
                    {active === 0?Data[0].list[0].Standard:active===1?Data[0].list[0].Scale:Data[0].list[0].Enterprise}
                    </div>
                    <div className="text-gray-500 text-sm text-normal">
                        Users
                    </div>
                </div>

                <div className="bg-white bg-opacity-30 text-black  rounded-l-3xl w-1/6 cursor-pointer" onClick={()=>{rightSliderHandler()}}>
                
                </div>
                </div>
                <div className="flex flex-row space-x-4 ">
                    <div className={`${active===0?"":"bg-opacity-40"} w-3 h-3 bg-white rounded-xl `}></div>
                    <div className={`${active===1?"":"bg-opacity-40"} w-3 h-3 bg-white rounded-xl `}></div>
                    <div className={`${active===2?"":"bg-opacity-40"} w-3 h-3 bg-white rounded-xl `}></div>
                </div>
            </div> 

            <div className="w-11/12 md:w-full mx-auto">
                <div className="flex flex-col w-full bg-white rounded-xl overflow-hidden mt-10 shadow-lg text-black ">
                <div className="hidden md:flex flex-row font-bold border-b-2 border-gray-100  ">
                    <div  className=" w-2/5 text-left py-3 pl-5 font-medium" > Licenses</div>
                    <div  className=" w-1/5 flex flex-row justify-center items-center md:border-l-2 md:border-r-2 md:borer-gray-100 py-3 text-base font-semibold"> <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/new_briefcase_icon_with_bg.svg" alt="thumbs icon" className="w-7 mr-1 my-0 "/> Standard</div>
                    <div className=" w-1/5 flex flex-row justify-center items-center py-3 pr-10 text-base font-semibold md:border-r-2"> <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/scale_icon_with_bg.svg" alt="meter icon" className="w-7 mr-1 my-0"/> Scale</div>
                    <div className=" w-1/5 flex flex-row justify-center text-base font-semibold items-center py-3 pr-10"> <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/3_blocks_icon_with_bg.svg" alt="enterprise icon" className="w-7 mr-1 my-0"/> Enterprise</div>
                </div>
                {
                    Data.map((set)=><div>
                        <div className="flex flex-row  border-b-2 border-gray-100 bg-gray-50">
                        <div  className="md:w-2/5 text-left text-xs font-medium  py-3 pl-5" > {set.title}</div>
                        <div  className=" md:w-1/5 flex flex-row justify-center items-center md:border-l-2 md:border-r-2 md:borer-gray-100 py-3 ">  </div>
                        <div className=" md:w-1/5 flex flex-row justify-center items-center py-3 pr-10 md:border-r-2">  </div>
                        <div className=" md:w-1/5 flex flex-row justify-center items-center py-3 pr-10">  </div>
                        </div>
                    {set.list.map((e)=>{
                        return(
                                <div className="flex flex-row border-b-2 justify-between border-gray-100">    
                                <div  className="md:w-2/5 text-left text-sm font-medium  py-2 pl-5 flex flex-row space-x-1 items-center" > <div> {e.title}</div> <div><img src="https://d2lxkizvrhu4im.cloudfront.net/icons/help_icon.svg" alt="help icon" className="m-0" /></div></div>
                                <div  className={`${!breakpoints.md?"flex":active===0?"flex":"hidden"}  flex-row  md:w-1/5 flex    justify-end md:justify-center py-2 pr-6 md:pr-0 md:border-l-2 md:border-r-2 md:borer-gray-100 font-normal text-sm `}>  {DataAnalyser(e.Standard)}</div>
                                <div className={`${!breakpoints.md?"flex":active===1?"flex":"hidden"} flex-row  md:w-1/5 flex  justify-end md:justify-center py-2 md:border-r-2 pr-6 md:pr-0 font-normal text-sm`}>  {DataAnalyser(e.Scale)}</div>
                                <div className={`${!breakpoints.md?"flex":active===2?"flex":"hidden"} flex-row md:w-1/5 flex  justify-end md:justify-center font-normal text-sm py-2 pr-6 md:pr-0`}>  {DataAnalyser(e.Enterprise)}</div>
                                </div>   
                        ) 
                    })} 
                </div>)
                }  
                <div className="flex flex-col md:flex-row justify-between font-bold border-b-2 border-gray-100  ">
                    <div  className="w-full md:w-2/5 text-left py-3 pl-5  font-medium text-sm flex flex-row items-center justify-center md:justify-start space-x-2" ><img alt="rocket icon" src="https://d2lxkizvrhu4im.cloudfront.net/icons/rocket_icon.svg" className="m-0 h-8"/> <span>Try for free 14 days</span></div>
                    <div  className=" w-full md:w-1/5 flex flex-row justify-center items-center md:border-l-2 md:border-r-2 md:borer-gray-100 py-3 md:pr-6 space-x-1"><span className=" font-semibold text-sm text-yellow-500">Learn more</span>  <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg" alt="bulb icon" className="h-1  m-0 transform -rotate-90"/>
                    </div>
                    <div className="w-full md:w-1/5  flex-row justify-center items-center py-3 md:pr-6 md:border-r-2 hidden md:flex space-x-1"> <span className="font-semibold text-sm text-yellow-500">Learn more</span>  <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg" alt="bulb icon" className="h-1  m-0 transform -rotate-90"/></div>
                    <div className=" w-full md:w-1/5 md:pr-6 flex-row justify-center items-center py-3 hidden md:flex space-x-1"> <span className="font-semibold text-sm text-yellow-500">Learn more</span>  <img src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg" alt="bulb icon" className="h-1  m-0 transform -rotate-90"/></div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Tariffs