import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const PrivacyFeaturesList = ({title,subTitle, DataArray }) => {
  const breakpoints = useBreakpoint();
  const [mapData, setMapData]=useState([...DataArray])
  const [showStatus, setShowStatus] = useState(0);
  return (
    <>
      <div className="">
        <div className="flex flex-col justify-center items-start md:justify-between  w-full md:w-2/3 mx-auto my-10 space-y-4">
         
        
          <div className="text-3xl md:text-5xl  font-semibold text-center  leading-snug">
            {title}
          </div>
          <div className="text-sm md:text-base text-center mx-auto text-gray-400 font-medium">
            {subTitle}
          </div>
         
         
        </div>

        <div className="flex flex-col md:flex-row flex-wrap  items-center">
          {mapData.map((Data, index) => (
            <div className="w-11/12 md:w-1/3 my-5 md:my-10 flex flex-col items-center md:items-start space-y-4">
              <div className=" text-center md:text-left flex flex-row  justify-center md:justify-start ">
                <img alt="data" src={Data.icon} className="m-0" />
              </div>

              <div className="font-semibold text-center md:text-left text-lg md:text-xl  w-4/5">
                {Data.title}
              </div>
              <div
                className={`text-sm text-gray-500 text-center  flex flex-col  ${
                  !breakpoints.md
                    ? null
                    : showStatus === index + 1
                    ? "flex"
                    : "hidden"
                }`}
              >
                <div className="flex flex-col  items-center md:items-start text-sm md:text-base  md:w-full mx-auto justify-center md:justify-start  space-y-4">
                  <div className="flex flex-col md:flex-row  space-y-2 md:space-y-0 md:space-x-2 "> <img className="m-0 h-4" alt="tick icon" src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_yellow.svg" /><span>{Data.listItem1}</span></div>
                  <div className="flex flex-col md:flex-row  space-y-2 md:space-y-0 md:space-x-2"> <img className="m-0 h-4" alt="tick icon" src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_yellow.svg" /><span>{Data.listItem2}</span></div>
                  <div className="flex flex-col md:flex-row  space-y-2 md:space-y-0 md:space-x-2"> <img className="m-0 h-4" alt="tick icon" src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_yellow.svg" /><span>{Data.listItem3}</span></div>
                  <div className="flex flex-col md:flex-row  space-y-2 md:space-y-0 md:space-x-2"> <img className="m-0 h-4" alt="tick icon" src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_yellow.svg" /><span>{Data.listItem4}</span></div>
                  

                </div>
              </div>
              <div className="md:hidden flex flex-row justify-center md:justify-start text-yellow-500 cursor-pointer">
                {showStatus === index + 1 ? (
                  <div
                    className="flex flex-row items-center text-sm space-x-2"
                    onClick={() => {
                      setShowStatus(0);
                    }}
                  >
                    <span>Hide</span>{" "}
                    <img
                      alt="data"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="m-0 transform rotate-180 h-2 -translate-y-0.5"
                    />
                  </div>
                ) : (
                  <div
                    className="flex flex-row text-sm items-center space-x-2"
                    onClick={() => {
                      setShowStatus(index + 1);
                    }}
                  >
                    <span>Show More</span>{" "}
                    <img
                      alt="data"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="m-0 h-2 "
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PrivacyFeaturesList;
