import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import PrivacyFeaturesList from "../features/privacyFeaturesWithList";
import Latest from "../latest/latest";
import Ready from "../ready/ready";
import Tariffs from "../tariffs/tariffs";
import FAQS from "../faqs/faqs";

import * as classes from "./price.module.scss";

const Price = () => {
  const PaymentData = [
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/user_with_bgCircle.svg",
      title: "Tools to build optimized checkout flows",
      listItem1: "Embeddable checkout",
      listItem2: "Custom UI toolkit",
      listItem3: "Simplified PCI compliance",
      listItem4: "Invoice support",
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/bulb_icon.svg",
      title: "Global payments with a single integration",
      listItem1: "Embeddable checkout",
      listItem2: "Custom UI toolkit",
      listItem3: "Simplified PCI compliance",
      listItem4: "Invoice support",
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/graph_icon.svg",
      title: "Comprehensive security and rigorous compliance",
      listItem1: "Embeddable checkout",
      listItem2: "Custom UI toolkit",
      listItem3: "Simplified PCI compliance",
      listItem4: "Invoice support",
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/question_mark_icon.svg",
      title: "Tools to build optimized checkout flows",
      listItem1: "Embeddable checkout",
      listItem2: "Custom UI toolkit",
      listItem3: "Simplified PCI compliance",
      listItem4: "Invoice support",
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/graph_icon.svg",
      title: "Global payments with a single integration",
      listItem1: "Embeddable checkout",
      listItem2: "Custom UI toolkit",
      listItem3: "Simplified PCI compliance",
      listItem4: "Invoice support",
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/graph_icon.svg",
      title: "Comprehensive security and rigorous compliance",
      listItem1: "Embeddable checkout",
      listItem2: "Custom UI toolkit",
      listItem3: "Simplified PCI compliance",
      listItem4: "Invoice support",
    },
  ];


  const FaqData=[
    {title:" Why don't you count usage/ active users/ tests taken as opposed to licensed users?", description:"Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use."},
    {title:"Can I buy just one product or feature / Do I have to buy the whole product suite?", description:"Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use."},
    {title:"Can I build a Proof-of-Concept before I buy?", description:"Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use."},
    {title:"Is Learnosity for me?", description:"Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use."},
    {title:"What’s your renewal process?", description:"Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use."},
    {title:"What is a user?", description:"Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use."}
  ]

  const breakpoints = useBreakpoint();
  return (
    <>
      <div className="font-sans">
        <div
          className={`text-white bg-cover bg-no-repeat h-full pb-16 overflow-hidden`}
          style={{
            backgroundImage: `${
              breakpoints.md
                ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/pricing_top_banner.svg")`
                : `url("https://d2lxkizvrhu4im.cloudfront.net/images/prices_bg.svg")`
            }`,
          }}
        >
          {/* <img src="images/background_dots.svg" className="lg:block hidden absolute -left-20 h-72 -top-4"/> */}
          <div className=" flex flex-col justify-center items-center ">
            <div className={`w-full lg:w-8/12 mx-4 lg:mx-auto my-20`}>
              <Tariffs />
            </div>
          </div>
          {/* <img src="images/background_dots.svg" className="lg:block hidden absolute -right-20 h-80 -bottom-0"/> */}
        </div>
        <div className={`conatiner font-sans`}>
          <div className= "w-11/12 md:w-8/12 my-20 mx-auto">
            <FAQS
              faqsType='FAQ'
              data={FaqData}
            />
          </div>
          <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
            <div className="my-20">
              <Latest />
            </div>

            <div className="my-20">
              <PrivacyFeaturesList title="Amet minim mollit non deserunt ullamco minim mollit" subTitle="Get 100+ features out of the box with Stripe’s integrated per-transaction pricing" DataArray={PaymentData} />
            </div>
            <div className="my-20">
              <Ready />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Price;
