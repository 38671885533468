import * as React from "react";

import Layout from "../components/layout/layout";
import PriceComponent from "../components/price/price";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const Price = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={`${t('press_room')}`}
        description={t('follow_all_the_news_from_mereos')}
      />
      <Layout>
        <PriceComponent />  
      </Layout>
    </>
  )
}

export default Price;
