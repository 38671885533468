import React from "react";
import { Link } from "gatsby";

import * as classes from "./latest.module.scss";
import { useTranslation } from 'react-i18next';

const GetLatest = ({ page, data }) => {
  const {t} = useTranslation();
  return (
    <div className="px-2 lg:pl-20 py-5 w-full  flex flex-col-reverse md:flex-row justify-between bg-gradient-to-r from-yellow-500 to-yellow-400 rounded-lg font-sans my-20">
      <div className="flex flex-col justify-center text-center md:text-left md:text-start items-center lg:justify-start lg:items-start w-11/12 mx-auto lg:w-3/5 my-10 space-y-6">
        <div className={`text-white  text-2xl md:text-5xl font-bold md:font-semibold leading-snug md:text-left ${classes.title}`}>
          {t('our_latest_publication')}
          {/* {data ? data.title1 : "Get the latest"} <br className=" md:hidden" />
          {data ? data.title2 : "from Source"} */}
        </div>
        <div className="text-white font-normal text-sm md:text-xl  text-center md:text-left lg:w-9/12 leading-loose">
          {
            data
              ? data.description
              : "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.Velit officia consequat duis amet minon mollit non deseruntullamco"
          }
        </div>
        <div className="flex flex-row w-full justify-center md:justify-start">
          <button className="bg-white py-3 px-7 w-full mx-auto md:mx-0 md:w-1/2 lg:w-1/3 md:px-10 md border-0 rounded-md  cursor-pointer font-semibold text-sm md:text-xl focus:outline-none hover:shadow-lg">
            <Link to="https://mereos.eu/blog/">
              {t('discover')}
              {/* {data ? data.btnText : "Learn More"} */}
            </Link>
          </button>
        </div>
      </div>

      <div className="w-full lg:w-2/5 flex flex-col items-start ">
        <img
          alt="market launch"
          src="https://d2lxkizvrhu4im.cloudfront.net/images/Kids_Studying_from_Home_right.svg"
          className="w-full max-h-80 m-0"
        />
      </div>
    </div>
  );
};

export default GetLatest;
